import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Fellows = makeShortcode("Fellows");
const Projects = makeShortcode("Projects");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "principles-de-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`Principles de Kernel`}<a parentName="h1" {...{
        "href": "#principles-de-kernel",
        "aria-label": "principles de kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h3 {...{
          "id": "️-principles-web-3",
          "style": {
            "position": "relative"
          }
        }}>{`⌛️ Principles Web 3`}<a parentName="h3" {...{
            "href": "#%EF%B8%8F-principles-web-3",
            "aria-label": "️ principles web 3 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <ol>
          <li parentName="ol">{`La confiance n'est pas seulement transactionnelle.`}</li>
          <li parentName="ol">{`Les vérités partagées créent de la valeur.`}</li>
          <li parentName="ol">{`Apprenez les limites. vous serez alors libre.`}</li>
          <li parentName="ol">{`L'argent peut parler.`}</li>
          <li parentName="ol">{`Les motivations expliquent tout.`}</li>
          <li parentName="ol">{`Libérez les institutions radicales.`}</li>
          <li parentName="ol">{`Résistez économiquement à la censure.`}</li>
          <li parentName="ol">{`La capacité de mise à l'échelle.`}</li>
        </ol>
      </Box>
      <Box mdxType="Box">
        <h3 {...{
          "id": "-principes-personels",
          "style": {
            "position": "relative"
          }
        }}>{`🌈 Principes Personels`}<a parentName="h3" {...{
            "href": "#-principes-personels",
            "aria-label": " principes personels permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <ol>
          <li parentName="ol">{`Jouer avec les modèles.`}</li>
          <li parentName="ol">{`Développez les moyens de signifier.`}</li>
          <li parentName="ol">{`Posez de meilleures questions.`}</li>
          <li parentName="ol">{`Considérez d'abord votre volonté.`}</li>
          <li parentName="ol">{`Écoutez et racontez de meilleures histoires.`}</li>
          <li parentName="ol">{`Les individus gouvernent bien ensemble.`}</li>
          <li parentName="ol">{`Apprenez à aimer apprendre.`}</li>
          <li parentName="ol">{`Le don est sacré.`}</li>
        </ol>
      </Box>
    </Column>
    <h2 {...{
      "id": "rencontrez-les-membres-de-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`Rencontrez les membres de KERNEL`}<a parentName="h2" {...{
        "href": "#rencontrez-les-membres-de-kernel",
        "aria-label": "rencontrez les membres de kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`.`}</p>
    <Aligner center mdxType="Aligner">
      <Fellows mdxType="Fellows" />
    </Aligner>
    <h2 {...{
      "id": "donner-dabord",
      "style": {
        "position": "relative"
      }
    }}>{`Donner d'abord`}<a parentName="h2" {...{
        "href": "#donner-dabord",
        "aria-label": "donner dabord permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Nous sommes ici pour nous servir les uns les autres, car nous sommes l'environnement de chacun. Le service authentique nous rapproche également de l'objectif de la connaissance de soi, qui est la réalisation qu'il n'y a, en vérité, aucune altérité.`}</p>
    <p>{`Nous avons également un objectif commun et externe : `}<strong parentName="p">{`servir la création continue d'argent gratuit`}</strong>{`. Dans ce contexte, Kernel est un cadeau. C'est ainsi que tout a commencé : Vivek a voulu rendre à une communauté qui lui avait donné un moyen de subsistance le type d'expérience qu'il avait trouvé si significatif dans ses années de formation. Andy a entrepris de donner à Vivek le type de modèle éducatif qui lui permettrait de le faire. Et chaque bloc est soigneusement et intentionnellement conçu avec toutes sortes de personnes douées, chacune unique, bien que nous portions tous le désir de partager nos dons avec le monde qui nous entoure. C'est le cœur encore battant d'une ancienne façon d'être que nous faisons lentement entrer dans le monde numérique.`}</p>
    <p>{`Nous apprenons les uns des autres et nous donnons de nous-mêmes jusqu'à ce que le soi et l'autre perdent le sentiment de séparation qui régit si souvent nos interactions. Il s'agit d'une vérité qui ne peut être ni donnée ni reçue, qui ne peut que croître dans votre propre cœur, à mesure que vous faites confiance à la valeur inaliénable de ce que vous êtes déjà.`}</p>
    <h2 {...{
      "id": "projets--équipes",
      "style": {
        "position": "relative"
      }
    }}>{`Projets & Équipes`}<a parentName="h2" {...{
        "href": "#projets--%C3%A9quipes",
        "aria-label": "projets  équipes permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`.`}</p>
    <Aligner center mdxType="Aligner">
      <Projects mdxType="Projects" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      